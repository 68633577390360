.ProductDetailMainContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5%;
}
.PD_Image_MainRapper {
  position: relative;
  height: 230px;
  width: 230px;
  display: flex;
  align-items: center;
}
.PD_Text_MainContainer {
  width: 40%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px;
}
.FeatureButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  /* margin-left: -35px; */
  position: absolute;
  top: 47%;
  left: -1%;
  z-index: 555;
}
.PD_NameTextStyle {
  font-size: 3.5rem;
  color: black;
  font-family: var(--Heading-font-family);
}
.PD_PriceTextStyle {
  font-size: 2rem;
  color: black;
  font-weight: bold;
  margin-top: 15px;
  font-family: var(--Heading-font-family);
}
.PD_DescriptionTextStyle {
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  font-family: var(--SubHeading-font-family);
  text-align: justify;
}

.ProductIconStyle {
  cursor: pointer;
}
