.categorySliderMainContainer {
  /* background-color: red; */
  width: 150px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: all 0.3s ease-in-out; /* Add a transition for a smooth hover effect */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.categorySliderMainContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.categorySliderMainContainerWrapper {
  /* background-color: red; */
  width: 20%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: all 0.3s ease-in-out; /* Add a transition for a smooth hover effect */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  height: 35vh;
}

.categorySliderMainContainerWrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.categoryLogoImageStyle {
  height: 100px;
  width: 120px;
  border-radius: 5px;
  margin-top: 3px;
}
.CategoryText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Limit to 2 lines for WebKit browsers */
  font-size: 1.4rem;
  font-weight: bold;
  font-family: var(--Heading-font-family);
  margin-top: 3px;
  text-align: center;
  width: 90%;
  margin-top: 5%;
  margin-bottom: 10px;

  /* display: flex;
  flex-direction: column;
  margin-top: 3px;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: var(--Heading-font-family);
  margin-top: 10px;
  -webkit-box-orient: vertical; */
}

.CategoryButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  position: absolute;
  bottom: 15px;
}

.CatButtonStyle {
  font-size: 1rem;
  font-family: var(--Heading-font-family);
  cursor: pointer;
  color: black;
  border-radius: 5px;
  background-color: var(--color-primary);
  padding: 5px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50px;
}
.CatButtonStyle:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.CatButtonStyle1 {
  font-size: 1rem;
  font-family: var(--Heading-font-family);
  cursor: pointer;
  color: black;
  border-radius: 5px;
  background-color: red;
  padding: 5px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50px;
}
.CatButtonStyle1:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
