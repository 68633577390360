.UploadImageWrapper {
  display: flex;
  justify-content: space-between;
}
.Add_Category_Image_container {
  background-color: #efefef;
  height: 150px;
  width: 150px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Empty_Prfile_Image_Style_Edit {
  height: 150px;
  width: 150px;
  border-radius: 100px;
  z-index: 1;
}
.Add_Category_Icon_Container {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.popup_formupload_input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.AddCategoryButton {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  height: 45px;
  padding: 0 60px;
  border-radius: 5px;
  cursor: pointer;
}
.AddCategoryButton:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.CategoryMainButtonWrapperContainer {
  display: flex;
  margin-top: 20px;
}

.Add_Category_Text_Style {
  color: white;
  font-size: 1.8rem;
  font-family: var(--SubHeading-font-family);
  margin-bottom: 0;
  font-weight: 500;
}

.Add_Category_detailinput_container_Input {
  background-color: #f5f5f5;
  width: 50%;
  border-radius: 25px;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1.5px solid #f5f5f5;
  box-sizing: border-box;
  position: relative;
  margin-top: 50px;
}

.Add_Category_input__box {
  flex: 1;
  width: 100%;
  min-height: 45px;
  height: 2.2rem;
  border: 1.5px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-left: 35px;
  padding-right: 10px;
  font-size: 16px;
}
.Add_Category_input__box::placeholder {
  color: #7b7b7b;
}
.Add_Category_input__box:focus {
  border: none;
  outline: none;
  border: 1.5px solid var(--color-secondary);
}
.Add_Category_input__box:hover {
  border: 1.5px solid var(--color-secondary);
}

.Add_Category_Error__message {
  font-size: 12px;
  color: red;
  position: absolute;
  margin-left: 35px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-family: var(--SubHeading-font-family);
  font-weight: 500;
}
.AddCategoryContentWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.All_Category_Text_Style {
  font-size: 3rem;
  font-family: var(--Heading-font-family);
  color: var(--color-primary);
  margin-top: 45px;
}
