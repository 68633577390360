.MainFooterContainer {
  background-color: var(--color-primary);
}
.FooterChildContainerRow {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 0 5%;
  justify-content: space-between;
  padding: 2% 0;
}
.ChildSubContainerVer1 {
  padding: 1.5rem;
  width: 25%;
}
.ChildSubContainerVer2 {
  padding: 1.5rem;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ChildSubContainerVer3 {
  padding: 1.5rem;
  width: 15%;
}
.ChildSubContainerVer4 {
  padding: 1.5rem;
  width: 35%;
}
.FootLogoStyle {
  width: 300px;
  height: auto;
}
.RowBlock1 {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.RowBlock2 {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.RowBlockIcon {
  width: 15px;
  height: 10.327px;
  flex-shrink: 0;
}

.RowBlockText {
  margin-left: 10px; /* Add some space between icon and text */
  color: var(--white, #fff);
  font-family: var(--SubHeading-font-family);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 26px; /* 162.5% */
}
.InfoIconStyle {
  margin-top: 5px;
}

.FooterHeading {
  color: var(--color-secondary);
  font-family: var(--SubHeading-font-family);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}
.QuickLinksStyle {
  color: var(--white, #fff);
  font-family: var(--SubHeading-font-family);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  text-decoration: none;
}
.LastBlockHead {
  color: var(--white, #fff);
  font-family: var(--SubHeading-font-family);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  max-width: 35vw;
}
.MonarchText {
  color: var(--color-secondary);
  font-family: var(--SubHeading-font-family);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
}
.LastBlockInputBox {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  /* opacity: 0.5; */
  padding: 3px;
}
.LastBlockInput {
  border-radius: 10px;
  flex: 1;
  border: none;
  outline: none;
  /* opacity: 1; */
  padding-left: 10px;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
}
.LastBlockInput::placeholder {
  color: var(--white, #fff);
  font-size: 18px;
  opacity: 0.2;
}
.ButtonStyle {
  display: flex; /* Add this to use flexbox */
  justify-content: center; /* Horizontally center content */
  align-items: center;
  border-radius: 7px;
  background-color: var(--color-secondary);
  box-shadow: 0px 4px 20px rgba(25, 207, 121, 0.2);
  width: 30%;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.FootText1 {
  color: var(--white, #fff);
  font-family: var(--SubHeading-font-family);
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  margin-top: 20px;
}
.FootText2 {
  color: var(--white, #fff);
  font-family: var(--SubHeading-font-family);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 200;
  line-height: 31px;
}
.PhoneNumberStyle {
  color: var(--color-secondary);
  font-family: var(--SubHeading-font-family);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 31px;
}
.UnderLineStyle {
  height: 0.5px;
  background: #fff;
  margin-left: 5.5%;
  margin-right: 5.5%;
  margin-bottom: 20px;
}
.FooterBaseMainContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5.5%;
  padding-right: 5.5%;
  padding-bottom: 40px;
}
.BaseChildContainerRow1 {
  display: flex;
  flex-direction: row;
}
.BaseChildContainerRow2 {
  display: flex;
  flex-direction: row;
}
.FollowUs {
  color: var(--white, #fff);
  text-align: center;
  font-family: var(--SubHeading-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SocialIcons {
  margin-left: 10px;
  width: 20.078px;
  height: 20px;
  flex-shrink: 0;
}
.BaseText {
  color: var(--white, #fff);
  text-align: center;
  font-family: var(--SubHeading-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.IconStyle {
  cursor: pointer;
}
