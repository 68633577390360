/* .swiperMainContentContainer {
    background-color: white;
    padding: 10px 10px;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      margin: 0 0 30px 30px;
  }
  .swiperMainContentContainer:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .SwiperImageContainer {
    position: relative;
  }
  .SwiperImageStyle {
    width: 100%;
    height: 250px;
    border-radius: 20px;
  }
  .SwiperContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
  .SwiperLocationTextNameStyle {
    color:black;
    font-weight: bold;
    font-size: 2.1rem;
    margin-top: 10px;
    margin-left: 5px;
  }
  .SwiperLocationTextStyle {
    color: #718e8e;
    font-size: 1.1rem;
    margin-top: 5px;
    margin-left: 8px;
  }
  .PriceContainerStyle{
    display: flex;
  }
  .SwiperPrice_Text_Style {
    font-size: 1.7rem;
    color: black;
    font-family: var(--SubHeading-font-family);
    font-weight: 700;
    margin-left: 6px;
    margin-top: 5px;
  }
  .RsTextStyle{
    font-size: 1.2rem;
    color: black;
    font-family: var(--SubHeading-font-family);
    font-weight: 700;
    margin-left: 6px;
    margin-top: 10px;
  } */

/* Container */
.swiperMainContentContainer {
  background-color: white;
  padding: 10px 10px;
  border-radius: 15px;
  cursor: pointer;
  margin: 15px 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
  margin-right: 30px;
  perspective: 1000px;
  /* Create a 3D space */
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  width: 22.5%;
}
.ProductswiperMainContentContainer {
  background-color: white;
  padding: 10px 10px;
  border-radius: 15px;
  cursor: pointer;
  margin: 15px 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
  margin-right: 30px;
  perspective: 1000px;
  /* Create a 3D space */
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  width: 30%;
}

.PD_Image_MainRapper {
  position: relative;
  /* height: 230px; */
  /* width: 270px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}

.FeatureButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100px;
  height: fit-content;
  /* margin-left: -35px; */
  /* position: absolute; */
  top: 47%;
  left: -3%;
  /* z-index: 555; */
}

.ProductswiperMainContentContainer:hover {
  /* transform: rotateY(360deg);  */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  /* box-shadow: rgba(29, 175, 161, 0.3) -5px 5px, rgba(29, 175, 161, 0.1) -10px 10px, rgba(29, 175, 161, 0.09) -15px 15px, rgba(29, 175, 161, 0.05) -20px 20px, rgba(29, 175, 161, 0.01) -25px 25px; */
  box-shadow: rgba(29, 175, 161, 0.25) 0px 14px 28px,
    rgba(29, 175, 161, 0.22) 0px 10px 10px;
  background-color: #f1f1f1;
}
.ProductswiperMainContentContainer:hover .SwiperImageStyle {
  transform: rotateY(360deg);
  transition: all 1s ease-in-out;
}
/* Card Flip Effect on Hover */
.swiperMainContentContainer:hover {
  /* transform: rotateY(360deg);  */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  /* box-shadow: rgba(29, 175, 161, 0.3) -5px 5px, rgba(29, 175, 161, 0.1) -10px 10px, rgba(29, 175, 161, 0.09) -15px 15px, rgba(29, 175, 161, 0.05) -20px 20px, rgba(29, 175, 161, 0.01) -25px 25px; */
  box-shadow: rgba(29, 175, 161, 0.25) 0px 14px 28px,
    rgba(29, 175, 161, 0.22) 0px 10px 10px;
  background-color: #f1f1f1;
}

/* Front and Back Faces of the Card */
.FrontFace,
.BackFace {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: transform 0.5s ease-in-out;
}

.FrontFace {
  background-color: white;
}

.BackFace {
  background-color: #f1f1f1;
  transform: rotateY(180deg);
  /* Initially hidden by rotating 180 degrees */
}

/* Image */
.SwiperImageStyle {
  width: 100%;
  height: 250px;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
}

.swiperMainContentContainer:hover .SwiperImageStyle {
  transform: rotateY(360deg);
  transition: all 1s ease-in-out;
}

/* Rest of your styles */
.SwiperLocationTextNameStyle {
  color: black;
  font-weight: bold;
  font-size: 2.1rem;
  margin-top: 10px;
  text-align: center;
  font-family: var(--Heading-font-family);
}

.SwiperLocationTextStyle {
  color: #718e8e;
  font-size: 1.1rem;
  margin-top: 5px;
  text-align: center;
  font-family: var(--SubHeading-font-family);
}

.PriceContainerStyle {
  display: flex;
  justify-content: center;
}

.SwiperPrice_Text_Style {
  font-size: 1.7rem;
  color: black;
  font-family: var(--SubHeading-font-family);
  font-weight: 700;
  margin-left: 6px;
  margin-top: 5px;
}

.RsTextStyle {
  font-size: 1.2rem;
  color: black;
  font-family: var(--SubHeading-font-family);
  font-weight: 700;
  margin-left: 6px;
  margin-top: 10px;
}
.Product_CategoryButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
