.Loader_Main_Div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure the loader stays on top of other content */
}

.Loader_Content {
  text-align: center;
}
