.PD_swiperMainContentContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  /* flex: 1; */
  height: 240px !important;
  position: relative;
}

.PD_SwiperImageStyle {
  position: relative;
  width: 60%;
}

.swiper-button-prev {
  color: #000;
}

.swiper-button-next {
  color: #000;
}
