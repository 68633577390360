* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body,
html {
  font-size: 62.5%;
}

.Login_Modal {
  width: 40vw;
  background-color: white;
  /* height: 60%; */
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  overflow: auto;
  border: none;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
}
.Login_Modal::-webkit-scrollbar {
  width: 5px;
}
.Login_Modal::-webkit-scrollbar-track {
  background-color: transparent;
}
.Login_Modal::-webkit-scrollbar-thumb {
  background: linear-gradient(#1fb295, #6fa66b);
  border-radius: 50px;
}
.Credential_Text_Style {
  color: var(--color-primary);
  font-size: 3rem;
  font-family: var(--Heading-font-family);
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
.Edit_Credential_Text_Style {
  color: var(--color-primary);
  font-size: 3rem;
  font-family: var(--Heading-font-family);
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.Login_Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 171, 161, 0.5);
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Login_input_container {
  background-color: #f5f5f5;
  width: 80%;
  border-radius: 25px;
  border: none;
  border: 1.5px solid #f5f5f5;
  box-sizing: border-box;
  position: relative;
  margin: 20px auto;
}

.Login_input__box {
  flex: 1;
  width: 100%;
  height: 4rem;
  border: 1.5px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-left: 35px;
  padding-right: 10px;
  font-size: 1.5rem;
}
.Login_input__box::placeholder {
  color: var(--color-primary);
}
.Login_input__box:focus {
  border: none;
  outline: none;
  border: 1.5px solid var(--color-secondary);
}
.Login_input__box:hover {
  border: 1.5px solid var(--color-secondary);
}
.error__message {
  font-size: 1.5rem;
  color: red;
  position: absolute;
  margin-left: 35px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-family: var(--SubHeading-font-family);
  font-weight: 500;
}

.LoginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  height: 45px;
  padding: 0 30px;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.LoginButton:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}

.Login_Text_Style {
  color: white;
  font-size: 2rem;
  font-family: var(--SubHeading-font-family);
  margin-bottom: 0;
  font-weight: 500;
  margin-left: 10px;
}
.CrossIconStyle {
  color: var(--color-primary);
  font-family: var(--Heading-font-family);
  font-size: 3rem;
  font-weight: bolder;
  position: absolute;
  right: 25px;
  top: 10px;
  cursor: pointer;
}

.Add_Edit_Category_Image_container {
  background-color: #efefef;
  height: 150px;
  width: 150px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.Product_Modal {
  width: 40vw;
  height: 70vh;
  background-color: white;
  /* height: 60%; */
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  overflow: auto;
  border: none;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
}
.Product_Modal::-webkit-scrollbar {
  width: 5px;
}
.Product_Modal::-webkit-scrollbar-track {
  background-color: transparent;
}
.Product_Modal::-webkit-scrollbar-thumb {
  background: linear-gradient(#1fb295, #6fa66b);
  border-radius: 50px;
}

.Add_Product_Category_Select_Container {
  font-size: 15px !important;
  color: var(--color-secondary);
  font-family: var(--SubHeading-font-family);
  border-radius: 25px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Add_Product_Text_Area_Style {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding-left: 35px;
  padding-right: 10px;
  padding-top: 15px;
  border: 1px solid #f2f2f2;
  font-size: 16px;
  color: #7b7b7b;
  resize: none;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.Add_Product_Text_Area_Style:focus {
  border: 1px solid var(--color-secondary);
  outline: none;
}
.Add_Product_Text_Area_Style:hover {
  border: 1px solid var(--color-secondary);
  outline: none;
}
.Edit_Product_Image_container {
  background-color: #efefef;
  height: 100px;
  width: 100px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
