.Add_Product_Image_container {
  background-color: #efefef;
  height: 150px;
  width: 150px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}
.Product_Empty_Prfile_Image_Style_Edit {
  height: 200px;
  width: 200px;
  border-radius: 20px;
  z-index: 1;
}

.Add_Product_Icon_Container {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}
.dropDown {
  width: 100%;
  padding: 8px 5px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #000;
  margin-top: 2%;
}
.dropDownEdit {
  margin-left: 10%;
  width: 79%;
  padding: 15px 15px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #f5f5f5;
  color: #000;
  margin-top: 2%;
  align-self: center;
}
.Add_Emp_Category_Select_Container {
  font-size: 15px !important;
  color: var(--color-secondary);
  font-family: var(--SubHeading-font-family);
  border-radius: 25px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.Add_Emp_Category_Select_Option_Style .css-1jqq78o-placeholder {
  color: #808080 !important;
}

.Add_Emp_Category_Select_Option_Style .css-1xc3v61-indicatorContainer {
  color: black !important;
}

.Add_Emp_Category_Select_Option_Style .css-1dimb5e-singleValue {
  color: black !important;
}

.Add_Product_detailinput_container_Input {
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 25px;
  border: none;
  margin-bottom: 15px;
  border: 1.5px solid #f5f5f5;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
}

.Add_Category_Text_Area_Input_Wrapper {
  display: flex;
  width: 100%;
}

.Add_Service_Text_Area_Style {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding-left: 35px;
  padding-right: 10px;
  padding-top: 15px;
  border: 1px solid #f2f2f2;
  font-size: 16px;
  color: #7b7b7b;
  /* resize: none; */
  width: 100%;
}
.Add_Service_Text_Area_Style:focus {
  border: 1px solid var(--color-secondary);
  outline: none;
}
.Add_Service_Text_Area_Style:hover {
  border: 1px solid var(--color-secondary);
  outline: none;
}
.ProductsInputMainContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;
}

.Product_Select_Dropdown {
  width: 47%;
}
